import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  main_menu: MenuItem[];
  user: any;
  active = false;
  items: MenuItem[];

  constructor(public auth: AngularFireAuth, private router: Router) {
    this.user = this.auth.authState;

    this.auth.authState.subscribe(user => {
      if (user) {
        this.main_menu = [
          {
            label: 'Home',
            icon: 'pi pi-pw pi-home',
            routerLink: '/main'
          },
          {
            label: 'Topics',
            icon: 'pi pi-pw pi-folder',
            routerLink: '/topics'
          },
          {
            label: 'Ministers',
            icon: 'pi pi-pw pi-user',
            routerLink: '/ministers'
          },
          {
            label: 'Ministries',
            icon: 'pi pi-pw pi-folder',
            routerLink: '/ministries'
          },
          {
            label: 'Musicians',
            icon: 'pi pi-pw pi-user',
            routerLink: '/musicians'
          },
          {
            label: 'Music',
            icon: 'pi pi-pw pi-play',
            routerLink: '/music'
          },
          {
            label: 'Radio Channels',
            icon: 'pi pi-pw pi-folder',
            routerLink: '/radio-channels'
          },
          {
            label: 'TV Channels',
            icon: 'pi pi-pw pi-folder',
            routerLink: '/tv-channels'
          },
          {
            label: 'Chats Tracker',
            icon: 'pi pi-pw pi-comments',
            routerLink: '/chats-tracker'
          },
          {
            label: 'Youtube Videos',
            icon: 'pi pi-pw pi-play',
            routerLink: '/youtube'
          },
          {
            label: 'Youtube Sermons',
            icon: 'pi pi-pw pi-play',
            routerLink: '/youtube-sermons'
          },
          {
            label: 'Clients',
            icon: 'pi pi-pw pi-users',
            routerLink: '/clients'
          }
        ];
        this.active = true;
        //this.router.navigate(['/main']);
      } else {
        //this.router.navigate(['/logout']);
        this.active = false;
        this.main_menu = [];
      }
    });
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Authentication',
        items: [
          {
            label: 'Login', icon: 'pi pi-fw pi-sign-in', command: (event) => {
              this.login();
            }
          },
          {
            label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: (event) => {
              this.logout();
            }
          }
        ]
      }];
  }

  login() {
    /*const promise = this.auth.signInWithPopup(new auth.GoogleAuthProvider());
    promise.then(_ => {
      this.router.navigate(['/main']);
      console.log('Login Complete');
    }).catch(err => {
      console.log(err, 'Login Error');
    });*/
  }

  logout() {
    const promise = this.auth.signOut();
    promise.then(_ => {
      console.log('Logout Complete');
    }).catch(err => {
      console.log(err, 'Logout Error');
    });

  }

}
