
<div class="content">
    <h3 class="step-title color-scheme">Delete Account</h3>
    <p>If you no longer want to use our Service, then you can delete your account. Please note that your account will be permanently deleted and cannot be recovered. Please follow the following steps to delete your account:
    </p>
    <ul>
        <li>Open the app on your mobile phone and login with your existing account if not already logged in.</li>
        <li>Open the Left Menu via the burger menu icon on the top left of the home page then select the Delete Account menu</li>
        <li>Confirm your account deletion</li>
        <li>Once you confirm, your request will be submitted to the system and immediately your account will be deleted</li>
        <li>The app will log you out once the deletion is completed.</li>
        <li>If after account deletion, you login to the app again, the system will prompt you to create a new account</li>
    </ul>
    <p>If you face any challenges deleting your account, do not hesitate to contact us at info@godsent.app</p>
    <div class="text-start pb-5">
        <a href="mailto:info@godsent.app" class="btn btn-primary rounded-pill text-white px-5">Contact Us</a>
    </div>
</div>
    