// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAp-VGFdg6N9rpSIfgvy5-uRVgwaaAWQxs",
    authDomain: "godsent-app.firebaseapp.com",
    databaseURL: "https://godsent-app.firebaseio.com",
    projectId: "godsent-app",
    storageBucket: "",
    messagingSenderId: "596444860420",
    appId: "1:596444860420:web:fb2e241ac237a397"

  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
