<!--=== Header section Starts ===-->
<div id="header" class="header-section">

    <!--=== Home Section Starts ===-->
    <div id="section-home" class="home-section-wrap center">
        <div class="section-overlay"></div>
        <div class="container home">
            <div class="row">
                <h1 class="well-come">Celebrating Christ</h1>
                <div class="col-md-8 col-md-offset-2">
                    <p class="intro-message">Godsent is an all gospel mobile application that hosts Gospel Music, Gospel
                        Sermons and various Christian content.</p>
                    <br /><br />
                    <div class="col-md-10 col-md-offset-1 center section-title">
                        <h3>Download Godsent Now</h3>
                    </div>
                    <div class="download-buttons clearfix">
                        <!-- Download Buttons -->
                        <a class="fancy-button button-line no-text btn-col large zoom"  target="_blank" href="http://hyperurl.co/q4t9q9"
                            title="Download from App store">
                            <span class="icon">
                                <i class="fa fa-apple"></i>
                            </span>
                        </a>
                        <a class="fancy-button button-line btn-col no-text large zoom" target="_blank"
                            href="http://hyperurl.co/fcq5x3"
                            title="Download from Google Play store">
                            <span class="icon">
                                <i class="fa fa-android"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--=== Home Section Ends ===-->
</div>

<!--=== Features section Starts ===-->
<section id="section-feature" class="feature-wrap">
    <div class="container features center">
        <div class="row">
            <div class="col-lg-12">
                <!--Features container Starts -->
                <ul id="card-ul" class="features-hold baraja-container">

                    <li class="single-feature" title="Card style">
                        <img src="../assets/images/7.png" alt="" class="feature-image" />
                    </li>
                    <li class="single-feature" title="Card style">
                        <img src="../assets/images/6.png" alt="" class="feature-image" />
                    </li>
                    <li class="single-feature" title="50+ SVG Icon included">
                        <img src="../assets/images/5.png" alt="" class="feature-image" />
                    </li>
                    <li class="single-feature" title="Album">
                        <img src="../assets/images/4.png" alt="" class="feature-image" />
                    </li>
                    <li class="single-feature" title="Contacts">
                        <img src="../assets/images/3.png" alt="" class="feature-image" />
                    </li>
                    <li class="single-feature" title="Contacts">
                        <img src="../assets/images/2.png" alt="" class="feature-image" />
                    </li>
                    <li class="single-feature" title="Contacts">
                        <img src="../assets/images/1.png" alt="" class="feature-image" />
                    </li>

                </ul>
                <!--Features container Ends -->

                <!-- Features Controls Starts -->
                <div class="features-control relative">
                    <button class="control-icon fancy-button button-line no-text btn-col bell" id="feature-prev"
                        title="Previous">
                        <span class="icon">
                            <i class="fa fa-arrow-left"></i>
                        </span>
                    </button>
                    <button class="control-icon fancy-button button-line no-text btn-col zoom" id="feature-expand"
                        title="Expand">
                        <span class="icon">
                            <i class="fa fa-expand"></i>
                        </span>
                    </button>
                    <button class="control-icon fancy-button button-line no-text btn-col zoom" id="feature-close"
                        title="Collapse">
                        <span class="icon">
                            <i class="fa fa-compress"></i>
                        </span>
                    </button>
                    <button class="control-icon fancy-button button-line no-text btn-col bell" id="feature-next"
                        title="Next">
                        <span class="icon">
                            <i class="fa fa-arrow-right"></i>
                        </span>
                    </button>
                </div>
                <!-- Features Controls Ends -->
            </div>
        </div>
    </div>
</section>
<!--=== Features section Ends ===-->

<!--=== Video section Starts ===-->
<section id="section-video" class="section-video-wrap">
    <div class="section-overlay"></div>
    <div class="container big-video center animated" data-animation="fadeInLeft" data-animation-delay="700">
        <div class="row">
            <div class="col-md-12 section-title">
                <h3>Installing Godsent App</h3>
            </div>
            <div class="col-md-10 col-md-offset-1 video-content">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/IPWZpotA3gA">
                </iframe>
            </div>
        </div>
    </div>
</section>

<section id="section-video" class="section-video-wrap">
    <div class="section-overlay"></div>
    <div class="container big-video center animated" data-animation="fadeInLeft" data-animation-delay="700">
        <div class="row">
            <div class="col-md-12 section-title">
                <h3>Albums on Godsent App</h3>
            </div>
            <div class="col-md-10 col-md-offset-1 video-content">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/eiyMhnPtInI">
                </iframe>
            </div>
        </div>
    </div>
</section>
<!--=== Video section Ends ===-->